import { BrowserRouter, Routes, Route } from "react-router-dom";

import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";

import Home from "./Pages/Home/Home";
import HTML from "./Pages/HTML/HTML";
import CSS from "./Pages/CSS/CSS";
import JavaScript from "./Pages/JavaScript/JavaScript";
import Blog from "./Pages/Blog/Blog";
import Projects from "./Pages/Projects/Projects";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/html" element={<HTML />} />
          <Route exact path="/css" element={<CSS />} />
          <Route exact path="/javascript" element={<JavaScript />} />
          <Route exact path="/blog" element={<Blog />} />
          <Route exact path="/projects" element={<Projects />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
