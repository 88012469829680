import React from "react";
import Hero from "../../components/Hero/Hero";

const JavaScript = () => {
  return (
    <div>
      <Hero Title={'JavaScript'} />
    </div>
  );
};

export default JavaScript;
