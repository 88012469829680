import React from "react";
import Hero from "../../components/Hero/Hero";
import Recommended from "../../components/Recommended/Recommended";
import Blog from "../../components/Blog/Blog";

const Home = () => {
  return (
    <div>
      <Hero Title={"Welcome to Think And Code"} />
      <Recommended />
      <Blog />
    </div>
  );
};

export default Home;
