import React from "react";
import "./Hero.css";

const Hero = ({Title}) => {
  return (
    <div className="hero container">
      <div className="hero-text">
        <div className="hero-left"></div>
        <div className="hero-right">
          <h1>
            {Title}
          </h1>
          <p>
            Think And Code offers free tutorials on HTML, CSS, JavaScript, React
            JS and more.
            You can learn HTML, CSS, JS and many more things related to web development.
          </p>
          <button className="btn">Explore more</button>
        </div>
      </div>
    </div>
  );
};

export default Hero;
