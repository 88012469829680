import React from "react";
import "./Blog.css";

const Blog = () => {
  return (
    <div className="container">
      <h1 className="title">Letest Blogs</h1>
      <div className="blogs">
        <div>
            <h2>What is HTML?</h2>
            <p>HTML (HyperText Markup Language) was created by Tim Berners-Lee in 1991 as a standard for creating web pages. It's a markup language used to structure content on the web, defining elements like headings, paragraphs, links, and images. HTML forms the backbone of web content. In layman's terms, HTML is like the skeleton of a website. It's a set of instructions that tells a web browser how to display text, images, videos, and other elements on a webpage. Think of it as the building blocks that create the structure and look of a website, similar to how bricks and mortar are used to build a house.</p>
        </div>
        <div>
            <h2>What is CSS?</h2>
            <p>CSS stands for Cascading Style Sheets. It is a stylesheet language that is used to describe the visual presentation of a web page written in HTML (Hypertext Markup Language). HTML creates the structure of the page, while CSS adds styling to that structure. This tutorial assumes that you have prior knowledge of HTML. If that's not the case, you can follow the HTML tutorial first.</p>
        </div>
        <div>
            <h2>What is JavaScript?</h2>
            <p>
                JavaScript is a lightweight, OOP language.
                It is a scripting language for web pages.
                It is used to add interactivity and dynamic effects to web pages.
                “.js” is the extension.
                Nowadays used in server-side development.
                JS Frontend Frameworks: React, Angular, Vue.
                JS Backend Frameworks: Express, Node.
            </p>
        </div>
      </div>
    </div>
  );
};

export default Blog;
