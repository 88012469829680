import React from "react";
import Hero from "../../components/Hero/Hero";

const CSS = () => {
  return (
    <div>
      <Hero Title={'CSS'} />
    </div>
  );
};

export default CSS;
