import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";
import logo from "../../assets/logo_white.png";

const Navbar = () => {
  const [sticky, setSticky] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      window.scrollY > 100 ? setSticky(true) : setSticky(false);
    });
  }, []);

  return (
    <nav className={`container ${sticky ? "dark-nav" : ""}`}>
      <div className="logo-container">
        <img src={logo} alt="" className="logo" />
        <p>Think And Code</p>
      </div>
      
      <ul>
        <li><Link to="/" >Home</Link></li>
        <li><Link to="/html" >HTML</Link></li>
        <li><Link to="/css" >CSS</Link></li>
        <li><Link to="/javascript" >JavaScript</Link></li>
        <li><Link to="/blog" >Blog</Link></li>
        <li className="btn"><Link to="/contact" >Contact us</Link></li>
      </ul>
    </nav>
  );
};

export default Navbar;
