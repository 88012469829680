import React from "react";
import "./Recommended.css";
import { Link } from "react-router-dom";

import HTML from "../../assets/html.webp";
import CSS from "../../assets/css.webp";
import JS from "../../assets/js.webp";
import Projects from "../../assets/projects.webp";

const Recommended = () => {
  return (
    <div className="container">
      <h1 className="title">Recommended</h1>
      <div className="recommended">
        <div>
          <Link to="/html">
            <h3>HTML</h3>
            <img src={HTML} alt="" />
          </Link>
        </div>

        <div>
          <Link to="/css">
            <h3>CSS</h3>
            <img src={CSS} alt="" />
          </Link>
        </div>
        <div>
          <Link to="/javascript">
            <h3>JavaScript</h3>
            <img src={JS} alt="" />
          </Link>
        </div>
        <div>
          <Link to='/projects'>
            <h3>Projects</h3>
            <img src={Projects} alt="" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Recommended;
