import React from 'react'
import Hero from '../../components/Hero/Hero'

const Blog = () => {
  return (
    <div>
      <Hero Title={'Blog'} />
    </div>
  )
}

export default Blog
