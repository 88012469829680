import React from 'react'
import Hero from '../../components/Hero/Hero'

const Projects = () => {
  return (
    <div>
      <Hero />
    </div>
  )
}

export default Projects
