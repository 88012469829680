import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <div className="footer container">
      <p>&copy; 2024 Think And Code. All rights reserved.</p>
      <ul>
        <li><a href="/">Terms of Services</a></li>
        <li><a href="/">Privacy Policy</a></li>
      </ul>
    </div>
  );
};

export default Footer;
